import { initialValues, validationSchema } from "./constants";
import { CAR_TYPES } from "./constants";
import { FUEL_TYPES } from "./constants";
import { SELECT_CUSTOM_CONTROL_STYLES } from "./constants";
import PlainFuelStationDashboardLayout from "components/layout/FuelStation/plainFuelStationDashboardContainer";
import EmptyTable from "components/pages/authenticated-new/pages/Events/shared/EmptyTable";
import { Button } from "components/shared/Button";
import TextInput from "components/shared/forms/TextInput";
import ThemedSelect from "components/shared/forms/ThemedSelect";
import { ROUTE_ENUMS } from "enums";
import { Field, Form, Formik } from "formik";
import usePagination from "hooks/usePagination";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import {
  createVehicleRequest,
  fetchPaginatedDriversRequest,
  fetchVehicleRequest,
  updateVehicleRequest,
} from "state/slices/fuel";
import { fetchVehicleManufacturerRequest } from "state/slices/fuel";
import {
  convertModelArrayToSelectOptions,
  getAPIErrorsForFormik,
  getOneLinerErrorMessage,
} from "utilities";

const FuelBuyerVehiclesFormPage = ({}) => {
  const { push } = useHistory();
  const params = useParams();
  const { id } = params;
  const asEdit = !!id;
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.users.data);
  const [vehicle, setVehicle] = useState({});
  const [isFetchingVehicle, setIsFetchingVehicle] = useState(false);
  const [manufacturerData, setManufacturerData] = useState();
  const getVehicle = useCallback(async () => {
    setIsFetchingVehicle(true);

    const resultAction = await dispatch(fetchVehicleRequest(id));
    if (fetchVehicleRequest.fulfilled.match(resultAction)) {
      setVehicle(resultAction?.payload);
      setIsFetchingVehicle(false);
    } else {
      setIsFetchingVehicle(false);
      toast.error(
        getOneLinerErrorMessage(resultAction.payload) ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) getVehicle();
  }, [getVehicle, id]);

  const [isLoading, setIsLoading] = useState(false);

  const createVehicle = async (formValues, formikHelpers) => {
    setIsLoading(true);

    const payload = {
      data: {
        ...formValues,
        type: formValues.type.value,
        fuelType: formValues.fuelType.value,
        ownerId: user?.id,
        ownerType: "COMPANY",
        driverId: formValues.driverId.value,
        manufacturer: manufacturerData,
      },
    };

    console.log(payload);
    const resultAction = await dispatch(createVehicleRequest(payload.data));
    if (createVehicleRequest.fulfilled.match(resultAction)) {
      setIsLoading(false);
      formikHelpers.resetForm();
      toast.success("Successfully added vehicle");
      push("/dashboard/fuel-station/vehicles");
    } else {
      setIsLoading(false);
      if (resultAction.payload.message) {
        toast.error(resultAction.payload.message);
      } else {
        formikHelpers.setErrors(getAPIErrorsForFormik(resultAction.payload));
        setIsLoading(false);
        toast.error(
          getOneLinerErrorMessage(resultAction.payload) ||
            resultAction.error?.message ||
            "An error occurred, please try again."
        );
      }
    }
  };

  const editVehicle = async (formValues, formikHelpers) => {
    setIsLoading(true);

    const payload = {
      data: {
        ...formValues,
        type: formValues.type.value,
        fuelType: formValues.fuelType.value,
        manufacturer: formValues.manufacturer.value,
      },
    };
    const resultAction = await dispatch(updateVehicleRequest(payload.data));
    if (updateVehicleRequest.fulfilled.match(resultAction)) {
      setIsLoading(false);
      formikHelpers.resetForm();
      toast.success("Successfully updated vehicle");
      push("/dashboard/fuel-station/vehicles");
    } else {
      setIsLoading(false);
      if (resultAction.payload.message) {
        toast.error(resultAction.payload.message);
      } else {
        formikHelpers.setErrors(getAPIErrorsForFormik(resultAction.payload));
        toast.error(
          getOneLinerErrorMessage(resultAction.payload) ||
            resultAction.error?.message ||
            "An error occurred, please try again."
        );
      }
    }
  };

  const { data: vehicleManufacturalData } = usePagination({
    dataSelector: "",
    request: fetchVehicleManufacturerRequest,
    customQueryPayload: {},
  });

  const {
    data: driversData,
    loading,
    refetch,
  } = usePagination({
    dataSelector: "",
    request: fetchPaginatedDriversRequest,
    customQueryPayload: {},
  });

  const convertDriversData = convertModelArrayToSelectOptions(
    driversData,
    "id",
    "name",
    true
  );

  const navigate = useHistory();

  const addDrivers = () =>
    navigate.push(`${ROUTE_ENUMS.DASHBOARD_FUEL_STATION_DRIVERS}/create`);

  const listManufacturer = vehicleManufacturalData?.data || [];

  const MAP_MANUFACTURER = listManufacturer?.map((items) => ({
    label: items,
    value: items,
  }));

  const convertManufacturalList = convertModelArrayToSelectOptions(
    MAP_MANUFACTURER,
    "label",
    "value",
    true
  );

  const defaultValues = {
    ...initialValues,
    manufacturer: manufacturerData,
  };

  return (
    <div>
      <PlainFuelStationDashboardLayout
        header={`${asEdit ? "Edit" : "Add"} Vehicle`}
      >
        <div>
          <Formik
            initialValues={
              asEdit
                ? {
                    ...vehicle,
                    fuelType: {
                      label: vehicle?.fuelType,
                      value: vehicle?.fuelType,
                    },
                    type: {
                      label: vehicle?.type?.toUpperCase(),
                      value: vehicle?.type?.toUpperCase(),
                    },
                    manufacturer: {
                      label: vehicle?.manufacturer,
                      value: vehicle?.manufacturer,
                    },
                  }
                : defaultValues
            }
            validationSchema={validationSchema}
            onSubmit={asEdit ? editVehicle : createVehicle}
            enableReinitialize
          >
            {({ handleSubmit, isValid, values }) => {
              setManufacturerData(values?.manufacturer?.value);

              return (
                <Form onSubmit={handleSubmit}>
                  <div className="tw-flex tw-flex-col tw-gap-8">
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-8 tw-gap-y-8">
                      <Field
                        component={ThemedSelect}
                        name="type"
                        id="type"
                        placeholder="Type"
                        options={CAR_TYPES}
                        customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                        required
                      />

                      <div>
                        <Field
                          component={ThemedSelect}
                          name="fuelType"
                          id="fuelType"
                          placeholder="Fuel Type"
                          options={FUEL_TYPES}
                          customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group tw-w-full tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-8 tw-gap-y-8">
                      <div>
                        <Field
                          component={ThemedSelect}
                          name="manufacturer"
                          id="manufacturer"
                          placeholder="Manufacturer"
                          options={convertManufacturalList}
                          customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                          required
                        />
                      </div>

                      <div>
                        <Field
                          component={TextInput}
                          className="tw-input"
                          name="licenseNo"
                          id="licenseNo"
                          type="text"
                          placeholder="Vehicle Reg/Licence No"
                        />
                      </div>

                      <div>
                        <Field
                          component={TextInput}
                          className="tw-input"
                          name="model"
                          id="model"
                          type="text"
                          placeholder="Vehicle Model"
                        />
                      </div>

                      <div>
                        <Field
                          component={TextInput}
                          className="tw-input"
                          name="manufacturerYear"
                          id="manufacturerYear"
                          type="text"
                          placeholder="Vehicle Mfg Year"
                        />
                      </div>
                    </div>

                    <div>
                      {driversData?.length >= 1 ? (
                        <Field
                          component={ThemedSelect}
                          name="driverId"
                          id="driverId"
                          placeholder="Select Driver"
                          options={convertDriversData}
                          customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                          required
                        />
                      ) : (
                        <div className="tw-mt-4">
                          <EmptyTable
                            title="No Driver added yet"
                            description=" Once any driver(s) is added, they will appear here."
                            className="tw-mb-3"
                            value="Add Driver"
                            onClick={addDrivers}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="tw-flex tw-gap-3 tw-justify-end tw-mt-5">
                    <Button
                      onClick={() => push("/dashboard/fuel-station/vehicles")}
                      type="button"
                      variant="outline"
                      disabled={isLoading}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      variant="primary"
                      loading={isLoading}
                      disabled={isLoading || !isValid}
                    >
                      {isLoading ? "Saving..." : asEdit ? "Submit" : "Submit"}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </PlainFuelStationDashboardLayout>
    </div>
  );
};

export default FuelBuyerVehiclesFormPage;
