import React from "react";
import PhoneInput from "react-phone-input-2";

const PhoneNumberInput = ({ label }) => {
  return (
    <>
      <label>{label}</label>
      <PhoneInput
        inputProps={{
          name: "phone",
          required: true,
          autoFocus: true,
        }}
        country={"ng"}
        inputStyle={{
          width: "100%",
          paddingTop: "1.5em ",
          paddingBottom: "1.5em ",
          borderRadius: "5px",
        }}
      />
    </>
  );
};

export default PhoneNumberInput;
